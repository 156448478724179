import React from 'react'
import Layout from '../components/Layout'
import { graphql } from "gatsby"
import * as projectStyles from '../stylesheets/pages/project.module.scss'
import Img from "gatsby-image"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faExternalLinkAlt, faChevronCircleUp } from '@fortawesome/free-solid-svg-icons'
import { faGithub } from "@fortawesome/free-brands-svg-icons"
import { startAnimation, techEnter } from '../gsap/project'
import { useInView } from 'react-intersection-observer';
import scrollTo from 'gatsby-plugin-smoothscroll';

export default function ProjectTemplate({ data }) {
    const { markdownRemark } = data 
    const { allFile } = data
    const { frontmatter, html } = markdownRemark
    const { ref, inView, entry } = useInView({
      threshold: 0.8,
      triggerOnce: true
    });
    const techStack = []

    allFile.edges.map(item => {
      return {
        name: item.node.name,
        childImageSharp: item.node.childImageSharp
      }
    })
    .forEach(item => {
      if (frontmatter.techStack.includes(item.name)) {
        techStack.push(item)
      }
    })
  
    let frontImage = React.useRef(null)
    let textHeader = React.useRef(null)
    let textDescription = React.useRef(null)
    let buttons = React.useRef(null)
    let textArrow = React.useRef(null)
    let animate

    React.useEffect(() => {
      animate = new startAnimation({
        frontImage,
        textHeader,
        textDescription,
        textArrow,
        buttons,
      })
    }, [])


    if (inView) {
      techEnter(entry.target)
    }
    
    return (
        <Layout>
          <div className={projectStyles.showcaseContainer}>
            <div className={projectStyles.showcaseText}>
              <h2 ref={el => (textHeader = el)}className={projectStyles.textHeader}>{frontmatter.title}</h2>
              <p ref={el => (textDescription = el)}className={projectStyles.textDescription}>{frontmatter.description}</p>
              <div ref={el => (buttons = el)} className={projectStyles.buttonsContainer}>
                {frontmatter.liveLink && 
                  <a href={frontmatter.liveLink} target="_blank" rel="noopener noreferrer">
                  <button className={projectStyles.linkBtn}>live <FontAwesomeIcon  className={projectStyles.fontAwesomeIcon} icon={faExternalLinkAlt} /></button>
                  </a>
                }
                {frontmatter.githubLink &&
                  <a href={frontmatter.githubLink} target="_blank" rel="noopener noreferrer">
                    <button className={projectStyles.linkBtn}>github <FontAwesomeIcon  className={projectStyles.fontAwesomeIcon} icon={faGithub} /></button>
                  </a>
                }
              </div>
              <div className={projectStyles.textArrow} ref={el => (textArrow = el)}><FontAwesomeIcon onClick={() => scrollTo('#content')} icon={faChevronDown} /></div>
            </div>
            <div ref={el => (frontImage = el)} className={projectStyles.showcaseImage}>
              <div className={projectStyles.showcaseImageContainer}>
                <Img fluid={frontmatter.frontImage.childImageSharp.fluid} />
              </div>
            </div>
          </div>

          <div id="content" className={projectStyles.contentContainer}>
            <div  dangerouslySetInnerHTML={{ __html: markdownRemark.html }} className={projectStyles.contentText} />
              <div className={projectStyles.contentImages}>
                <div className={projectStyles.contentImage1}>
                  {frontmatter.mainImage1 &&
                    <Img fluid={frontmatter.mainImage1.childImageSharp.fluid} />
                  }
                </div>
                  {frontmatter.mainImage2 &&
                  <div className={projectStyles.contentImage2}>
                        <Img fluid={frontmatter.mainImage2.childImageSharp.fluid} />
                  </div>
                  }
                  {frontmatter.mainImage3 &&
                  <div className={projectStyles.contentImage3}>
                        <Img fluid={frontmatter.mainImage3.childImageSharp.fluid} />
                  </div>
                  }


              </div>
            </div>
              <div ref={ref} className={projectStyles.techStack}>
                {techStack.map((item) => (
                  <div className={projectStyles.techStackItem} key={item.name}>
                    <Img fluid={item.childImageSharp.fluid} />  
                  </div>
                ))}
                <FontAwesomeIcon onClick={() => scrollTo('#navbar')}className={projectStyles.returnTopIcon} icon={faChevronCircleUp} />
              </div>
        </Layout>
    )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        slug
        title
        description
        liveLink
        githubLink
        techStack
        mainImage1 {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        mainImage2 {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        mainImage3 {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        frontImage {
          childImageSharp {
            fluid(quality: 90) {
							...GatsbyImageSharpFluid	
            }
          }
        }
      }
    }
    allFile(filter: {relativeDirectory: {eq: "tech"}}) {
      edges {
        node {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid	
            }
          }
          name
        }
      }
    }
  }
`