// extracted by mini-css-extract-plugin
export var arrowBounceDown = "project-module--arrowBounceDown--athBC";
export var arrowBounceRight = "project-module--arrowBounceRight--Wxehs";
export var buttonsContainer = "project-module--buttonsContainer---CAMF";
export var contentContainer = "project-module--contentContainer--AKa0p";
export var contentImage1 = "project-module--contentImage1--mfofJ";
export var contentImage2 = "project-module--contentImage2--LE+yn";
export var contentImage3 = "project-module--contentImage3--+CwI2";
export var contentImages = "project-module--contentImages--epXpe";
export var contentText = "project-module--contentText--XvW9I";
export var fontAwesomeIcon = "project-module--fontAwesomeIcon--WHRzL";
export var linkBtn = "project-module--linkBtn--y-GZf";
export var returnTopIcon = "project-module--returnTopIcon--3wetl";
export var revealImage = "project-module--revealImage--iFEkZ";
export var showcaseContainer = "project-module--showcaseContainer--wohWs";
export var showcaseImage = "project-module--showcaseImage--n6VN0";
export var showcaseImageContainer = "project-module--showcaseImageContainer--+jPWQ";
export var showcaseText = "project-module--showcaseText--ej6El";
export var techStack = "project-module--techStack--chj7+";
export var techStackItem = "project-module--techStackItem--hmTUA";
export var textArrow = "project-module--textArrow--+xY36";
export var textDescription = "project-module--textDescription--xZncz";
export var textHeader = "project-module--textHeader--zDR9V";