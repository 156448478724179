import gsap  from "gsap"

export function techEnter(entry) {
    gsap.to([...entry.children], {
        y: 0,
        opacity: 1,
        stagger: 0.1
    })
}

export class startAnimation {
    constructor(props){
        this.frontImage = props.frontImage
        this.textHeader = props.textHeader
        this.textDescription = props.textDescription
        this.textArrow = props.textArrow
        this.buttons = props.buttons
        this.init();
    }

    moveText () {
        gsap.to(this.textHeader, { x: 0, duration: 0.8, delay: 0.2 })
        gsap.to(this.textDescription, { x: 0, duration: 0.8, delay: 0.3 })
        gsap.to(this.textArrow, { x: 0, duration: 0.8, delay: 0.4 })
        gsap.to(this.buttons, { x: 0, duration: 0.8, delay: 0.4 })
        gsap.to(this.textArrow, {
            y: -10,
            yoyo: true,
            repeat: -1,
            duration: 1
        })
    }

    moveImage () {
        gsap.to(this.frontImage, { opacity: 1 })
        gsap.to(this.frontImage, {
            x: 0,
            y: 0,
            duration: 1.5,
            ease: "easeInOut"
        })
    }

    init() {
        this.moveText();
        this.moveImage();
    }
}